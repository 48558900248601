<template>
    <div class="d-flex all-after-header-box">
        <!-- Левое меню модуля -->
        <div 
            :class="{'drawer-left-wrap' : true, 'dlw-large' : (!drawerLeftMenuMini), 'drawer-menu-hover': drawerLeftMenuHover }"
            @mouseenter="LeftMenuMouseenter()"
            @mouseleave="LeftMenuMouseleave()"
        >
            <v-navigation-drawer
                absolute
                permanent
                class="drawer-menu"
                v-model="drawerLeftMenu"
            >
                <template v-if="true">
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        class="dm-actions-list"
                    >
                        <v-list-item-group
                            color="#009c95"
                        >
                            <v-list-item
                                link
                                class="v-item--active v-list-item--active drawer-menu-list-bigbtn"
                                @click="onCreateClicked"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-plus-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("Добавить") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>
                        <template v-for="item in correspondenceItems">
                            <v-list-item
                                link
                                v-if="$validatePermission(item.access)"
                                :key="item.route"
                                :to="{ name: item.route }"
                            >
                                <v-list-item-icon>
                                    <v-badge
                                        :value="item.badge"
                                        color="red"
                                        overlap
                                        bottom
                                        dot
                                        >
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-badge>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>

               
                <template>
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        shaped
                    >
                        <v-list-item-group>
                            <v-list-item
                                link
                                :to="{ name: 'EdsLightInWork' }"
                            >
                                <v-list-item-icon>
                                <v-icon>far fa-cogs</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("В_работе") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                <template>
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        shaped
                    >
                        <v-list-item-group>
                            <v-list-item
                                link
                                :to="{ name: 'EdsLightHandled' }"
                            >
                                <v-list-item-icon>
                                <v-icon>far fa-cogs</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("Обработанные") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

            </v-navigation-drawer>
        </div>

        <div class="content-box">
            <div v-if="$route.name == 'EdsLight'">
                Модуль "ЭДС-Light"
            </div>
            <router-view v-else :key="$router.fullPath" />
            
        </div>

        <CreateCorrespondenceDocumentModal ref="CreateDlg"/>
    </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';
import CreateCorrespondenceDocumentModal from '@/components/documents/dialogs/CreateCorrespondenceDocumentDlg.vue';
import { httpAPI } from "@/api/httpAPI";
import _ from 'lodash'

export default {
    name: "EdsLightMain",
    components: {
        CreateCorrespondenceDocumentModal
    },
    asyncComputed: {

    },
    computed: {
        ...mapGetters(
        {
            drawerLeftMenuMini: 'isDrawerLeftMenuMinimized'
        }),
    },
    data: () => ({
        drawerLeftMenu: true,
        drawerLeftMenuHover: false,

        group1: {
            active: false
        },

        correspondenceItems: [
            {
                type: "project",
                name: "Проекты",
                route: "EdsLightProjects",
                icon: "far fa-folder-open",
                access: { permissions: [] },
                badge: false
            },
            {
                type: "incoming",
                name: "Входящие",
                route: "EdsLightIncomings",
                icon: "far fa-inbox-in",
                access: { permissions: [] },
                badge: false
            },
            {
                type: "outgoing",
                name: "Исходящие",
                route: "EdsLightOutgoings",
                icon: "far fa-inbox-out",
                access: { permissions: [] },
                badge: false
            },
            {
                type: "inner",
                name: "Внутренние",
                route: "EdsLightInners",
                icon: "far fa-inbox",
                access: { permissions: [] },
                badge: false
            },
        ],
    }),
    methods: {
        ...mapActions('global/actionsource', ['loadDataSource']),
        LeftMenuMouseenter() {
            if (this.drawerLeftMenuMini)
                this.drawerLeftMenuHover = true;
        },
        LeftMenuMouseleave() {
            this.drawerLeftMenuHover = false;  
        },
        async onCreateClicked() {
            try
            {
                let { params } = await this.$refs.CreateDlg.open();
                await this.loadDataSource({ createType: params.createType, navigate: true, common: params.isCommon ?? false });
            }
            catch (ex)
            {
                console.log(ex);
            }
        },
        updateCounters: _.debounce(async function () { 
            clearInterval(this.counterTimer);
            var counterResponse = await httpAPI({ url: `/api/edslight/counter`, method: 'GET', skipErrorHandler: true });
          
            let counterTypes = [
                "incoming",
                "outgoing",
                "inner",
            ];

            counterTypes.forEach((name, index) => {
                let idx = this.correspondenceItems.findIndex(x => x.type == name);
                if (idx != -1) {
                    this.correspondenceItems[idx].badge = counterResponse?.data?.Payload?.Data?.Object?.[index];
                }
            });
            this.counterTimer = setInterval(async () => {
                await this.updateCounters();
            }, 60000);  
        }, 250),
    },
    mounted() {
        this.updateCounters();

        this.$eventBus.$on('update_edslight_counters', async () => {
            this.updateCounters();
        });
    },
    beforeDestroy() {
        clearInterval(this.counterTimer);
        this.$eventBus.$off('update_edslight_counters');
    }
}
</script>